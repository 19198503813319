.postContainer {
    margin: auto;
    padding-top: 80px;
    max-width: 600px;
    font-size: 18px;
    padding-right: 30px;
    padding-left: 30px;
}

.backgroundWhite {
    background: white;
    min-height: 100%;
    width: 100%;
    padding-bottom: 10px;
    position: absolute;
}

.date {
    font-size: 15px;
}