.about {
    margin-right: 12%;
    margin-left: 12%;
    margin-top: 20px;
    font-size: 21px;
}

.middle {
    text-align: center;
}

.about-align {
    /* margin-left:12% */
    padding-left: 12%;
}