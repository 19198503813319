@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: AquireBold;
  src: url(/static/media/AquireBold-8Ma60.cfc5ce07.otf) format("opentype");
}

@font-face {
  font-family: Aquire;
  src: url(/static/media/Aquire-BW0ox.a37a828d.otf) format("opentype");
}

body {
    background-color: #e6e9f0;
    /* font-family: sans-serif; */
    /* font-family: Raleway; */
}

#heading {
    /* background-color: #bccbde; */
    color: #fcfcfc;
    font-size: 80px;
    font-weight: bold;
    /* margin: 20px; */
    padding-top: 100px;
    /* margin-left: 150px; */
    font-family: 'Aquire';
    font-weight: 500;
}

@media (max-width: 800px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    #heading {
        font-size: 50px;
    }
  }

.subheading {
    font-size: 35px;
    /* padding: 100px;  */
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 20px;
    font-weight: bold;
    font-family: 'Raleway';
    font-weight: 400;
    /* text-align: center; */
}

@media (max-width:1000px){
    .subheading {
        text-align: center;
    }

}

#grad {
    text-align: center;
    /* padding: 0px; */
    /* margin: 0px; */
    width: 100%;
    color:#fcfcfc;
    /* background-image: linear-gradient(to bottom right, #1e61c9,#75aaff); */
    background-image: linear-gradient(to bottom right, #1e61c9,#7DCCFF);
    padding-bottom: 50px;
    margin-bottom: 20px;
}

#search {
    width: 210px;
}
.pageLink:hover {
    background-color: #353A40;
    color: #fcfcfc;
}

.pageLink {
    width: 150px;
    text-align: center;
    color: #353A40;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    margin: 100px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 3px;
    border-color: #353A40;
    border-radius: 50px;
}
a {
    /* width: 100px; */
    /* height: 100px; */
    /* background: red; */
    /* transition: width 2s; */
    color: black;
}

a:link { 
    text-decoration: none; 
    color: black;
}


a:visited { 
    text-decoration: none; 
    /* color: black; */
}


a:hover { 
    text-decoration: none; 
    color: black;
}


a:active { 
    text-decoration: none; 
    color: black;
}

.postCard:hover {
    /* background: red; */
    /* opacity: 0.75; */
    color: black;
    transform: scale(1.05);
    /* height: 200px; */
}

p:hover {
    color: black;
}


.postCard {
    /* position: fixed; */
    transition: transform 0.2s;
    margin: 30px;
    padding: 20px;
    border-radius: 25px;
    background-color: white;
    max-width: 370px;
    justify-content: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width:1000px){
    .postCard {
        margin-top: 10px;
    }

}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #4a4a4a;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.flex-child {
    flex: 1 0 33%; /* explanation below */
}

.center {
    justify-content: center;
}

.postImage {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    height: 150px;
    overflow: hidden;
    border-radius: 20px
}

.read {
    text-decoration: underline;
    color: #347deb;
    /* margin-left: 5px; */
}

li.read {
    text-decoration: underline;
    color: #347deb;
}

span.read{
    margin-left: 5px;
}
body {
    width: 100%;
}
.contain {
    /* min-width: 100%; */
    padding-top: 70px;
    /* margin-right: 70px; */
}
.postContainer {
    margin: auto;
    padding-top: 80px;
    max-width: 600px;
    font-size: 18px;
    padding-right: 30px;
    padding-left: 30px;
}

.backgroundWhite {
    background: white;
    min-height: 100%;
    width: 100%;
    padding-bottom: 10px;
    position: absolute;
}

.date {
    font-size: 15px;
}
.about {
    margin-right: 12%;
    margin-left: 12%;
    margin-top: 20px;
    font-size: 21px;
}

.middle {
    text-align: center;
}

.about-align {
    /* margin-left:12% */
    padding-left: 12%;
}
